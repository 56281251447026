<template>
  <v-card flat>
    <v-container>
      <v-card-title class="text-h6">
        <v-spacer></v-spacer>
        <div>
          <span class="font-weight-bold"> Featured Challenges  </span>
          <div class="mx-10 divider-light mt-3" :class="{'divider-dark': $vuetify.theme.dark}"></div>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
    </v-container>
    <v-card-text class="text-center" v-if="$apollo.loading">
      <v-skeleton-loader
        v-bind="attrs"
        type="article, actions"
      ></v-skeleton-loader>
    </v-card-text>
    <v-card-text v-else>
      <splide :options="options">
        <splide-slide v-for="(challenge, i) in challenges" :key="i">
          <v-card flat color="primary" class="rounded-xl pl-10 d-flex justify-center" min-height="300">
            <v-card color="transparent" flat>
              <v-card-title class="white--text">
                <span class="mt-10 text-h4" :class="{'black--text': theme}">{{ challenge.node.title }}</span>
              </v-card-title>
              <v-card-text class="white--text">
                <span :class="{'black--text': theme}" v-html="challenge.node.minDescription"> </span>
              </v-card-text>
              <v-card-actions>
                <v-btn color="black" class="white--text pa-5" :to="`/challenge/overview/${challenge.node.pk}`"> Attempt </v-btn>
              </v-card-actions>
            </v-card>
            <v-spacer></v-spacer>
            <div class="mr-5 mt-12">
              <v-img width="200" height="200" :src="debugMode ? `${$staticUrl}/${challenge.node.image}` : challenge.node.image"></v-img>
            </div>
          </v-card>
        </splide-slide>
      </splide>
    </v-card-text>
  </v-card>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import { CHALLENGE_HOMEPAGE } from '@/queries/challenge';
import { DEBUG } from '@/settings/';

export default {
  name: 'challengesComponent',
  mixins: [colorMxn],
  data() {
    return {
      options: {
        rewind:true,
        perPage: 2,
        gap: '1rem',
        focus: 'center',
        trimSpace: false,
        start:1
			},
      model: 0,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      challenges:[],
    }
  },
  computed: {
    debugMode() {
      return DEBUG;
    }
  },
  apollo: {
    challenges: {
      query: CHALLENGE_HOMEPAGE,
      variables: {
        "challengesFirst": 4,
      },
      update: (data) => {
        return data.challenges.edges;
      }
    }
  },
  methods: {
  }
}
</script>

<style>
/* @import '@splidejs/splide/dist/css/themes/splide-default.min.css'; */
</style>
